
<template>
  <Header/>
  <div>
    <div>
      <label for="project">Project</label>
      <input class="setList" list="projectList" @change="updateProjectsInput" v-model="projectCurrentName" :data-value="projectCurrent" type="text" id="project" placeholder="Проект" />
      <datalist id="projectList">
        <option v-for="item in projects" 
          :key="item.id" 
          :data-value="item.id"
          :value="item.name">
        </option>
      </datalist>
    </div>
    <div>
      <label for="token">Token</label>
      <div v-for="token in tokens" :key="token.token">
        <input class="setList" type="checkbox" :id="'token_' + token.token" :value="{ id: token.token, name: token.name }" v-model="selectedTokens">
        <label :for="'token_' + token.token">{{ token.site }} | {{ token.name }}</label>
      </div>
    </div>
    <div>
      <label for="utm">UTM группа</label>
      <div v-for="utmGroup in utmGroups" :key="utmGroup.id">
        <input type="checkbox" :id="'utmGroup_' + utmGroup.id" :value="{ id: utmGroup.id, name: utmGroup.name }" v-model="selectedUtmGroups">
        <label :for="'utmGroup_' + utmGroup.id">{{ utmGroup.name }}</label>
      </div>
    </div>
    <div>
      <label for="dateStart">Дата начала периода:</label>
      <input type="date" id="dateStart" v-model="dateStart">
    </div>
    <div>
      <label for="dateEnd">Дата конца периода:</label>
      <input type="date" id="dateEnd" v-model="dateEnd">
    </div>
    <button @click="fetchData">Запросить</button>
    <ag-grid-vue
      :rowData="rowData"
      :columnDefs="colDefs"
      style="height: 500px"
      class="ag-theme-quartz"
    >
    </ag-grid-vue>
  </div>
    <!--table v-if="data" class="table-stat">
      <thead>
        <tr>
          <th>Название</th>
          <th>Разворот вниз на блоке</th>
          <th>Разворот вверх на блоке</th>
          <th>Ушли с блока при первом проходе вниз (без разворота)</th>
          <th>Общий скоринг нерелевантности блока</th>
          <th>Сколько зашло на блок, прочитало (более 1 секунды), и перешло на следующий блок - пролистало вниз (не вышло и не ушло в разворот вверх)</th>
          <th>Всего зашло на блок (долистало до блока без разворота или перешли с меню на этот блок (то есть прокрутка вниз только учитывается - до разворота). Независимо от проведенного времени на данном блоке - даже если 0 сек</th>
          <th>Процент доходимости в блоке</th>
          <th>Процент от ВСЕГО ЗАШЛО к общему скорингу нерелевантности блока</th>
          <th>Количество пользователей, потерянных на блоке</th>
          <th>Соотношение H2/I2 - процент от зашедших на блок, к тем кто его читает и переходит к следующему (качество ознакомления с информацией в блоке) (процент)</th>
          <th>Пролистали блок сколько раз (сами менее 1 секунды) до разворота</th>
          <th>Пролистали блок сколько раз (автоматически из-за выбранного пункта меню) до разворота</th>
          <th>Аффинити к блоку (стремление его читать,  - среднее время и количество пользователей при расчете выборки которые смотрели этот блок) до разворота. Формат показан ниже - надо выгрузить не только количество, а еще отсортированные значения по времени (с выбросами)</th>
          <th>Аффинити к блоку  - количество пользователей (без выбросов)</th>
          <th>Аффинити к блоку  - среднее время (без выбросов)</th>
          <th>Аффинити к блоку (стремление его читать,  - среднее время и количество пользователей при расчете выборки которые смотрели этот блок) до разворота. Формат показан ниже - надо выгрузить не только количество, а еще отсортированные значения по времени (с выбросами)</th>
          <th>Аффинити к блоку  - количество пользователей (с выбросами)</th>
          <th>Аффинити к блоку  - среднее время (с выбросами)</th>
          <th>Аффинити к блоку (стремление его читать) - количество нажатий на пункт меню, обозначающий данный блок (нажатие на меню независимо от блока где в этот момент находится пользователь)  до разворота</th>
          <th>Аффинити к блоку - количество раз где пролистывали все блоки до этого - вручную, без использования меню (менее 1 секунды), а на данном блоке задержались на более чем 1 секунду (при прокрутке вниз - до разворота)</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(name, index) in Object.keys(data)" :key="name">
          <td>{{ name }}</td>
          <td>{{ data[name].down }}</td>
          <td>{{ data[name].up }}</td>
          <td>{{ data[name].left }}</td>
          <td>{{ data[name].up + data[name].left }}</td>
          <td>{{ data[name].countToRead }}</td>
          <td>{{ data[name].countToView }}</td>
          <td>{{ data[getBlockName(index)].countToView / data[getBlockName(0)].countToView*100}}</td>
          <td>{{ (data[name].up + data[name].left)/data[name].countToView*100 }}</td>
          <td>{{ data[getBlockName(index)].countToView - data[getBlockName(index+1)].countToView }}</td>
          <td>{{ data[name].countToRead/data[name].countToView*100 }}</td>
          <td>{{ data[name].scrolledBlockWithoutMenu }}</td>
          <td>{{ data[name].scrolledBlockFromMenu }}</td>
          <td>{{ data[name].arrSecondView }}</td>
          <td>{{ data[name].lengthArrSecondView }}</td>
          <td>{{ data[name].avgSecondView }}</td>
          <td>{{ data[name].filteredArrSecondView }}</td>
          <td>{{ data[name].lengthFilteredArrSecondView }}</td>
          <td>{{ data[name].avgFilteredSecondView }}</td>
          <td>{{ data[name].clickToMenu }}</td>
          <td></td>
        </tr>
      </tbody>
    </table>
    <div v-if="!data">Данные не найдены</div-->
  
  
</template>

<style>
  th {
    vertical-align: bottom;
  }
  .ag-theme-quartz {
      --ag-header-height: 130px;
  }


  .setList {
    width: 30%;
  }

</style>

<script>
/*

*/
import { ref } from 'vue';
import axios from "axios";
// Получение токена из localStorage
const token = localStorage.getItem('token');
// Добавление токена в заголовок Authorization для всех запросов
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { AgGridVue } from "ag-grid-vue3"; // AG Grid Component
import Header from '@/components/Header.vue';

export default {
  components: {
   AgGridVue, // Add AG Grid Vue3 component
   Header,
  },
  setup() {
 // Row Data: The data to be displayed.
 /*
    const rowData = ref([

    ]);
*/
    // Column Definitions: Defines the columns to be displayed.
    const colDefs = ref([
      { autoHeaderHeight: true, wrapHeaderText: true, headerName: "Название", field: "f10", pinned: "left" },
      { autoHeaderHeight: true, wrapHeaderText: true, headerName: "Разворот вниз на блоке", field: "f20", headerTooltip: "Разворот вниз на блоке" },
      { autoHeaderHeight: true, wrapHeaderText: true, headerName: "Разворот вверх на блоке", field: "f30", headerTooltip: "Разворот вверх на блоке" },
      { autoHeaderHeight: true, wrapHeaderText: true, headerName: "Ушли с блока при первом проходе вниз (без разворота)", field: "f40", headerTooltip: "Ушли с блока при первом проходе вниз (без разворота)" },
      { autoHeaderHeight: true, wrapHeaderText: true, headerName: "Общий скоринг нерелевантности блок", field: "f50", headerTooltip: "Общий скоринг нерелевантности блок" },
      { autoHeaderHeight: true, wrapHeaderText: true, headerName: "Сколько зашло на блок, прочитало (более 1 секунды), и перешло на следующий блок - пролистало вниз (не вышло и не ушло в разворот вверх)", field: "f60", headerTooltip: "Сколько зашло на блок, прочитало (более 1 секунды), и перешло на следующий блок - пролистало вниз (не вышло и не ушло в разворот вверх)"},
      { autoHeaderHeight: true, wrapHeaderText: true, headerName: "Всего зашло на блок (долистало до блока без разворота или перешли с меню на этот блок (то есть прокрутка вниз только учитывается - до разворота). Независимо от проведенного времени на данном блоке - даже если 0 сек", field: "f70", headerTooltip: "Всего зашло на блок (долистало до блока без разворота или перешли с меню на этот блок (то есть прокрутка вниз только учитывается - до разворота). Независимо от проведенного времени на данном блоке - даже если 0 сек" },
      { autoHeaderHeight: true, wrapHeaderText: true, headerName: "Процент доходимости в блоке", field: "f80", headerTooltip: "Процент доходимости в блоке" },
      { autoHeaderHeight: true, wrapHeaderText: true, headerName: "Процент от ВСЕГО ЗАШЛО к общему скорингу нерелевантности блока", field: "f90", headerTooltip: "Процент от ВСЕГО ЗАШЛО к общему скорингу нерелевантности блока" },
      { autoHeaderHeight: true, wrapHeaderText: true, headerName: "Количество пользователей, потерянных на блоке", field: "f100", headerTooltip: "Количество пользователей, потерянных на блоке" },
      { autoHeaderHeight: true, wrapHeaderText: true, headerName: "Процент от зашедших на блок, к тем кто его читает и переходит к следующему (качество ознакомления с информацией в блоке) (процент)", field: "f110", headerTooltip: "Процент от зашедших на блок, к тем кто его читает и переходит к следующему (качество ознакомления с информацией в блоке) (процент)" },
      { autoHeaderHeight: true, wrapHeaderText: true, headerName: "Пролистали блок сколько раз (сами менее 1 секунды) до разворота", field: "f120", headerTooltip: "Пролистали блок сколько раз (сами менее 1 секунды) до разворота" },
      { autoHeaderHeight: true, wrapHeaderText: true, headerName: "Пролистали блок сколько раз (автоматически из-за выбранного пункта меню) до разворота", field: "f130", headerTooltip: "Пролистали блок сколько раз (автоматически из-за выбранного пункта меню) до разворота" },
      { autoHeaderHeight: true, wrapHeaderText: true, autoHeight: true, wrapText: true, headerName: "Аффинити к блоку (стремление его читать,  - среднее время и количество пользователей при расчете выборки которые смотрели этот блок) до разворота.(с выбросами)", field: "f140", headerTooltip: "Аффинити к блоку (стремление его читать,  - среднее время и количество пользователей при расчете выборки которые смотрели этот блок) до разворота. (с выбросами)" },
      { autoHeaderHeight: true, wrapHeaderText: true, headerName: "Аффинити к блоку  - количество пользователей (с выбросами)", field: "f150", headerTooltip: "Аффинити к блоку  - количество пользователей (с выбросами)" },
      { autoHeaderHeight: true, wrapHeaderText: true, headerName: "Аффинити к блоку  - среднее время (с выбросами)", field: "f160", headerTooltip: "Аффинити к блоку  - среднее время (с выбросами)" },
      { autoHeaderHeight: true, wrapHeaderText: true, autoHeight: true, wrapText: true, headerName: "Аффинити к блоку (стремление его читать,  - среднее время и количество пользователей при расчете выборки которые смотрели этот блок) до разворота.  (без выбросов)", field: "f170", headerTooltip: "Аффинити к блоку (стремление его читать,  - среднее время и количество пользователей при расчете выборки которые смотрели этот блок) до разворота.  (без выбросов)" },
      { autoHeaderHeight: true, wrapHeaderText: true, headerName: "Аффинити к блоку  - количество пользователей (без выбросов)", field: "f180", headerTooltip: "Аффинити к блоку  - количество пользователей (без выбросов)" },
      { autoHeaderHeight: true, wrapHeaderText: true, headerName: "Аффинити к блоку  - среднее время (без выбросов)", field: "f190", headerTooltip: "Аффинити к блоку  - среднее время (без выбросов)" },
      { autoHeaderHeight: true, wrapHeaderText: true, headerName: "Аффинити к блоку (стремление его читать) - количество нажатий на пункт меню", field: "f200", headerTooltip: "Аффинити к блоку (стремление его читать) - количество нажатий на пункт меню, обозначающий данный блок (нажатие на меню независимо от блока где в этот момент находится пользователь)  до разворота" },
      { autoHeaderHeight: true, wrapHeaderText: true, headerName: "Аффинити к блоку - количество раз где пролистывали все блоки до этого - вручную", field: "f210", headerTooltip: "Аффинити к блоку - количество раз где пролистывали все блоки до этого - вручную, без использования меню (менее 1 секунды), а на данном блоке задержались на более чем 1 секунду (при прокрутке вниз - до разворота)" },
      

    ]);

    return {
      //rowData,
      colDefs,
    };
  },
  data() {
    return {
      clientId: '',
      dateStart: '',
      dateEnd: '', // Установка значения по умолчанию
      data: null,
      rowData: [],
      requested: false,
      tokens: [],
      projects: [],
      projectCurrent: '',
      projectCurrentName: '',
      utmGroups: [],
      selectedUtmGroups: [],
      selectedTokens: [],
      hostApi: process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : 'http://localhost:5001', 
    };
  },
  mounted() {
    let vm = this;
    console.log(process.env.VUE_APP_API_URL)
    // Устанавливаем значение dateEnd на сегодняшнюю дату
    vm.dateEnd = new Date().toISOString().substr(0, 10);
    this.getProjects();
  },
  methods: {
    updateUtmGroupInput(e){
      const selectedUtm = e.target.value;

      const selectedOption = this.utmGroups.find(token => token.name === selectedUtm);
      console.log('updateUtmGroupInput', selectedOption)
      if (selectedOption) {
        const inputValue = selectedOption.id;
        this.utmCurrent = inputValue;
      }
      //this.getTokens();
    },
    updateProjectsInput(e){
      const selected= e.target.value;
      const selectedOption = this.projects.find(item => item.name === selected);
      if (selectedOption) {
        const inputValue = selectedOption.id;
        this.projectCurrent = inputValue;
        localStorage.setItem('projectCurrent', inputValue);
        localStorage.setItem('projectCurrentName', selectedOption.name);
        //e.target.dataset.value = inputValue;
        this.getTokens();
        this.getUtmGroups()
      }
    },
    updateTokensInput(e) {//{{token.name}} | {{token.site}}
      const selectedToken = e.target.value;
      const selectedOption = this.tokens.find(token => token.name+' | '+token.site === selectedToken);
      if (selectedOption) {
        const inputValue = selectedOption.token;
        this.tokenCurrent = inputValue;
        //e.target.dataset.value = inputValue;
        
      }

    },
    getProjects() {
      console.log("getProject");
      let vm = this;
      axios
        .post(vm.hostApi + '/api/v1/project/get', 
          {
            clientId: localStorage.getItem('clientId'),
          }
        )
        .then(function(response) {
              vm.projects = response.data.data;
              console.log('getProject', response.data.data)
              console.log('vm.projects', vm.projects)
              
              if (localStorage.getItem('projectCurrent')!= null){
                const tmpProject = localStorage.getItem('projectCurrent');
                const tmpOpt = vm.projects.find(item => item.id === tmpProject);
                if (tmpOpt) {
                  vm.projectCurrent = localStorage.getItem('projectCurrent');
                  vm.projectCurrentName = localStorage.getItem('projectCurrentName');
                  vm.getTokens();
                }
              }
        })
        .catch(function(error) {
              console.log(error);
        });
    },
    getTokens() {
      console.log("getTokens")
      let vm = this;
      axios
      .post(vm.hostApi + '/api/v1/token/get', 
        {
            clientId: localStorage.getItem('clientId'),
            projectId: this.projectCurrent,
        })
     .then(function(response) {
          vm.tokens = response.data;
          console.log(response.data)
        })
     .catch(function(error) {
          console.log(error);
      });
    },
    getUtmGroups() {
      let vm = this;
      axios
      .post(vm.hostApi + '/api/v1/utmGroup/get', 
        {
          clientId: localStorage.getItem('clientId'),
          projectId: this.projectCurrent,
        })
      .then(function(response) {
        console.log(response.data.data)
          vm.utmGroups = response.data.data;
        })
      .catch(function(error) {
          console.log(error);
        });
    },
    getBlockName(index) {
      
      if (index < 0) {
        index = 0;
      }
      
      
      const keys = Object.keys(this.data);
      if (index > keys.length-1) {
        index = keys.length-1
      }
      //console.log(index, keys.length, keys[index]);
      if (index >= 0 && index < keys.length) {
        //console.log('+');
        return keys[index];
      } else {
        //console.log('-');
        return ''; // или другое значение, если предыдущего блока нет
      }
    },
    fetchData() {
      console.log(this.dateStart, this.dateEnd, 'utmCurrent', this.selectedTokens,  this.selectedUtmGroups);
      axios.post(this.hostApi+'/api/v1/getstreamxlsx', 
        {
            clientId: localStorage.getItem('clientId'),
            tokens: this.selectedTokens,
            utmGroups: this.selectedUtmGroups,
            dateStart: this.dateStart,
            dateEnd: this.dateEnd 
        }, {
          responseType: 'arraybuffer' // Указываем, что ожидаем бинарные данные
        })
        .then(response => {
          // Создаем ссылку на содержимое файла
          const url = window.URL.createObjectURL(new Blob([response.data]));

          // Создаем ссылку на скачивание файла
          const link = document.createElement('a');
          link.href = url;
          let fileName = 'статистика' + this.dateStart + '_' + this.dateEnd + '.xlsx';
          //console.log(fileName)

          fileName = fileName.replace(/\-/g, '_')
          // Устанавливаем имя файла
          link.setAttribute('download', fileName);

          // Добавляем ссылку на страницу и эмулируем клик для скачивания файла
          document.body.appendChild(link);
          link.click();

          // Удаляем ссылку после скачивания файла
          link.parentNode.removeChild(link);
        })
        .catch(error => {
          console.error('Ошибка при запросе данных:', error);
        });
    }
  }
};
</script>