<template>
  <div class="jumbotron">
    <h1 class="display-4">Метрика для вашего сайта!</h1>
    <p class="lead">Получите глубокие инсайты о посещаемости, поведении пользователей и многом другом с нашей метрикой. Оптимизируйте ваш сайт сегодня и достигните новых высот в онлайн-присутствии!</p>
    <hr class="my-4">
    <p></p>
    <p class="lead">
      <router-link class="btn btn-primary btn-lg" tag="button" to="/login">Логин</router-link>
    </p>
    <p class="lead">
      <router-link class="btn btn-primary btn-lg" tag="button" to="/register">Регистрация</router-link>
    </p>
  </div>
  <router-view></router-view>
</template>

<script>
/*
export default {
  data() {
    return {
      hostApi: process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : 'http://localhost:5001', 
    };
  },
  methods: {

  }
};
*/
</script>