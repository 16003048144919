
import { createApp, h } from 'vue'
import App from './App.vue';
import router from './router';

//createApp(App).use(router).mount('#app');
import './assets/bootstrap.css'
import './assets/bootstrap.bundle.min.js'

const app  = createApp({
    render: ()=>h(App)
});
app.use(router)
const vm = app.mount('#app')