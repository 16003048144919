
<template>
  <Header/>
  <div>
    <div>
      <label for="project">Project</label>
      <input class="setList" list="projectList" @change="updateProjectsInput" v-model="projectCurrentName" :data-value="projectCurrent" type="text" id="project" placeholder="Проект" />
      <datalist id="projectList">
        <option v-for="item in projects" 
          :key="item.id" 
          :data-value="item.id"
          :value="item.name">
        </option>
      </datalist>
    </div>
    <div>
      <label for="token">Token</label>
      <input class='setList' list="tokenList"  @change="updateTokensInput" v-model="tokenCurrentName" :data-value="tokenCurrent" type="text" id="token" placeholder="Сайт или страница" />
      <datalist id="tokenList">
        <option v-for="token in tokens" 
          :key="token" 
          :data-value="token.token" 
        >{{token.name}} | {{token.site}}</option>
      </datalist>
    </div>
    <div>
      <label for="utm">UTM группа</label>
      <input class="setList" list="utmList"  @change="updateUtmGroupInput" v-model="utmCurrentName" :data-value="utmCurrent" type="text" id="utmGroup" placeholder="Группа утм" />
      <datalist id="utmList">
        <option v-for="utmGroup in utmGroups" 
          :key="utmGroup" 
          :data-value="utmGroup.id" 
        >{{utmGroup.name}}</option>
      </datalist>
    </div>
    <div>
      <label for="dataPoints">Периоды изменения</label>
      <select class="setList" id="dataPoints" v-model="dataPointsCurrent" @change="changeDataPoints(dataPointsCurrent)">
        <option value="">Выберите период</option>
        <option v-for="(dp, index) in datePoints" :key="dp" :value="index">{{dp[0]}} - {{ dp[1] }}</option>
      </select>
    </div>
    <div>
      <label for="dateStart">Дата начала периода:</label>
      <input type="date" id="dateStart" v-model="dateStart" @change="updateDateTimeStart">
    </div>
    <div>
      <label for="dateEnd">Дата конца периода:</label>
      <input type="date" id="dateEnd" v-model="dateEnd" @change="updateDateTimeEnd">
    </div>
    <button @click="fetchData">Запросить</button>
    <ag-grid-vue
      :rowData="rowData"
      :columnDefs="colDefs"
      style="height: 700px"
      class="ag-theme-quartz"
    >
    </ag-grid-vue>
  </div>
    <!--table v-if="data" class="table-stat">
      <thead>
        <tr>
          <th>Название</th>
          <th>Разворот вниз на блоке</th>
          <th>Разворот вверх на блоке</th>
          <th>Ушли с блока при первом проходе вниз (без разворота)</th>
          <th>Общий скоринг нерелевантности блока</th>
          <th>Сколько зашло на блок, прочитало (более 1 секунды), и перешло на следующий блок - пролистало вниз (не вышло и не ушло в разворот вверх)</th>
          <th>Всего зашло на блок (долистало до блока без разворота или перешли с меню на этот блок (то есть прокрутка вниз только учитывается - до разворота). Независимо от проведенного времени на данном блоке - даже если 0 сек</th>
          <th>Процент доходимости в блоке</th>
          <th>Процент от ВСЕГО ЗАШЛО к общему скорингу нерелевантности блока</th>
          <th>Количество пользователей, потерянных на блоке</th>
          <th>Соотношение H2/I2 - процент от зашедших на блок, к тем кто его читает и переходит к следующему (качество ознакомления с информацией в блоке) (процент)</th>
          <th>Пролистали блок сколько раз (сами менее 1 секунды) до разворота</th>
          <th>Пролистали блок сколько раз (автоматически из-за выбранного пункта меню) до разворота</th>
          <th>Аффинити к блоку (стремление его читать,  - среднее время и количество пользователей при расчете выборки которые смотрели этот блок) до разворота. Формат показан ниже - надо выгрузить не только количество, а еще отсортированные значения по времени (с выбросами)</th>
          <th>Аффинити к блоку  - количество пользователей (без выбросов)</th>
          <th>Аффинити к блоку  - среднее время (без выбросов)</th>
          <th>Аффинити к блоку (стремление его читать,  - среднее время и количество пользователей при расчете выборки которые смотрели этот блок) до разворота. Формат показан ниже - надо выгрузить не только количество, а еще отсортированные значения по времени (с выбросами)</th>
          <th>Аффинити к блоку  - количество пользователей (с выбросами)</th>
          <th>Аффинити к блоку  - среднее время (с выбросами)</th>
          <th>Аффинити к блоку (стремление его читать) - количество нажатий на пункт меню, обозначающий данный блок (нажатие на меню независимо от блока где в этот момент находится пользователь)  до разворота</th>
          <th>Аффинити к блоку - количество раз где пролистывали все блоки до этого - вручную, без использования меню (менее 1 секунды), а на данном блоке задержались на более чем 1 секунду (при прокрутке вниз - до разворота)</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(name, index) in Object.keys(data)" :key="name">
          <td>{{ name }}</td>
          <td>{{ data[name].down }}</td>
          <td>{{ data[name].up }}</td>
          <td>{{ data[name].left }}</td>
          <td>{{ data[name].up + data[name].left }}</td>
          <td>{{ data[name].countToRead }}</td>
          <td>{{ data[name].countToView }}</td>
          <td>{{ data[getBlockName(index)].countToView / data[getBlockName(0)].countToView*100}}</td>
          <td>{{ (data[name].up + data[name].left)/data[name].countToView*100 }}</td>
          <td>{{ data[getBlockName(index)].countToView - data[getBlockName(index+1)].countToView }}</td>
          <td>{{ data[name].countToRead/data[name].countToView*100 }}</td>
          <td>{{ data[name].scrolledBlockWithoutMenu }}</td>
          <td>{{ data[name].scrolledBlockFromMenu }}</td>
          <td>{{ data[name].arrSecondView }}</td>
          <td>{{ data[name].lengthArrSecondView }}</td>
          <td>{{ data[name].avgSecondView }}</td>
          <td>{{ data[name].filteredArrSecondView }}</td>
          <td>{{ data[name].lengthFilteredArrSecondView }}</td>
          <td>{{ data[name].avgFilteredSecondView }}</td>
          <td>{{ data[name].clickToMenu }}</td>
          <td></td>
        </tr>
      </tbody>
    </table>
    <div v-if="!data">Данные не найдены</div-->
  
  
</template>

<style>
th {
  vertical-align: bottom;
}
.ag-theme-quartz {
    --ag-header-height: 130px;
}
.setList {
  width: 30%;
}
.ag-cell-value {
    white-space: pre-wrap; /* 'pre-wrap' сохраняет пробелы и переносы строк */
  }
</style>

<script>
/*

*/
import { ref } from 'vue';
import axios from "axios";
// Получение токена из localStorage
const token = localStorage.getItem('token');
// Добавление токена в заголовок Authorization для всех запросов
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { AgGridVue } from "ag-grid-vue3"; // AG Grid Component
import Header from '@/components/Header.vue';
export default {
  components: {
   AgGridVue, // Add AG Grid Vue3 component
   Header,
  },
  setup() {
 // Row Data: The data to be displayed.
 /*
    const rowData = ref([

    ]);
*/
    // Column Definitions: Defines the columns to be displayed.
    const colDefs = ref([
      { autoHeaderHeight: true, wrapHeaderText: true, headerName: "Название", field: "f10", pinned: "left" },
      { autoHeaderHeight: true, wrapHeaderText: true, headerName: "Дубли", field: "dubleBlock" },
      { autoHeaderHeight: true, wrapHeaderText: true, headerName: "Разворот вниз на блоке", field: "f20", headerTooltip: "Разворот вниз на блоке" },
      { autoHeaderHeight: true, wrapHeaderText: true, headerName: "Разворот вверх на блоке", field: "f30", headerTooltip: "Разворот вверх на блоке" },
      { autoHeaderHeight: true, wrapHeaderText: true, headerName: "Разворот вверх на блоке (Первый)", field: "f35", headerTooltip: "Разворот вверх на блоке (Первый)" },
      { autoHeaderHeight: true, wrapHeaderText: true, headerName: "Ушли с блока при первом проходе вниз (без разворота)", field: "f40", headerTooltip: "Ушли с блока при первом проходе вниз (без разворота)" },
      { autoHeaderHeight: true, wrapHeaderText: true, headerName: "Общий скоринг нерелевантности блок", field: "f50", headerTooltip: "Общий скоринг нерелевантности блок" },
      { autoHeaderHeight: true, wrapHeaderText: true, headerName: "Сколько зашло на блок, прочитало (более 1 секунды), и перешло на следующий блок - пролистало вниз (не вышло и не ушло в разворот вверх)", field: "f60", headerTooltip: "Сколько зашло на блок, прочитало (более 1 секунды), и перешло на следующий блок - пролистало вниз (не вышло и не ушло в разворот вверх)"},
      { autoHeaderHeight: true, wrapHeaderText: true, headerName: "Всего зашло на блок (долистало до блока без разворота или перешли с меню на этот блок (то есть прокрутка вниз только учитывается - до разворота). Независимо от проведенного времени на данном блоке - даже если 0 сек (с учетом блока разворота)", field: "f70", headerTooltip: "Всего зашло на блок (долистало до блока без разворота или перешли с меню на этот блок (то есть прокрутка вниз только учитывается - до разворота). Независимо от проведенного времени на данном блоке - даже если 0 сек (с учетом блока разворота)" },
      { autoHeaderHeight: true, wrapHeaderText: true, headerName: "Процент доходимости в блоке", field: "f80", headerTooltip: "Процент доходимости в блоке" },
      { autoHeaderHeight: true, wrapHeaderText: true, headerName: "Процент от ВСЕГО ЗАШЛО к общему скорингу нерелевантности блока", field: "f90", headerTooltip: "Процент от ВСЕГО ЗАШЛО к общему скорингу нерелевантности блока" },
      { autoHeaderHeight: true, wrapHeaderText: true, headerName: "Количество пользователей, потерянных на блоке", field: "f100", headerTooltip: "Количество пользователей, потерянных на блоке" },
      { autoHeaderHeight: true, wrapHeaderText: true, headerName: "Процент от зашедших на блок, к тем кто его читает и переходит к следующему (качество ознакомления с информацией в блоке) (процент)", field: "f110", headerTooltip: "Процент от зашедших на блок, к тем кто его читает и переходит к следующему (качество ознакомления с информацией в блоке) (процент)" },
      { autoHeaderHeight: true, wrapHeaderText: true, headerName: "Пролистали блок сколько раз (сами менее 1 секунды) до разворота", field: "f120", headerTooltip: "Пролистали блок сколько раз (сами менее 1 секунды) до разворота" },
      { autoHeaderHeight: true, wrapHeaderText: true, headerName: "Пролистали блок сколько раз (автоматически из-за выбранного пункта меню) до разворота", field: "f130", headerTooltip: "Пролистали блок сколько раз (автоматически из-за выбранного пункта меню) до разворота" },
      { autoHeaderHeight: true, wrapHeaderText: true, autoHeight: true, wrapText: true, headerName: "Аффинити к блоку (стремление его читать,  - среднее время и количество пользователей при расчете выборки которые смотрели этот блок) до разворота.(с выбросами)", field: "f140", headerTooltip: "Аффинити к блоку (стремление его читать,  - среднее время и количество пользователей при расчете выборки которые смотрели этот блок) до разворота. (с выбросами)" },
      { autoHeaderHeight: true, wrapHeaderText: true, headerName: "Аффинити к блоку  - количество пользователей (с выбросами)", field: "f150", headerTooltip: "Аффинити к блоку  - количество пользователей (с выбросами)" },
      { autoHeaderHeight: true, wrapHeaderText: true, headerName: "Аффинити к блоку  - среднее время (с выбросами)", field: "f160", headerTooltip: "Аффинити к блоку  - среднее время (с выбросами)" },
      { autoHeaderHeight: true, wrapHeaderText: true, autoHeight: true, wrapText: true, headerName: "Аффинити к блоку (стремление его читать,  - среднее время и количество пользователей при расчете выборки которые смотрели этот блок) до разворота.  (без выбросов)", field: "f170", headerTooltip: "Аффинити к блоку (стремление его читать,  - среднее время и количество пользователей при расчете выборки которые смотрели этот блок) до разворота.  (без выбросов)" },
      { autoHeaderHeight: true, wrapHeaderText: true, headerName: "Аффинити к блоку  - количество пользователей (без выбросов)", field: "f180", headerTooltip: "Аффинити к блоку  - количество пользователей (без выбросов)" },
      { autoHeaderHeight: true, wrapHeaderText: true, headerName: "Аффинити к блоку  - среднее время (без выбросов)", field: "f190", headerTooltip: "Аффинити к блоку  - среднее время (без выбросов)" },
      { autoHeaderHeight: true, wrapHeaderText: true, headerName: "Аффинити к блоку (стремление его читать) - количество нажатий на пункт меню", field: "f200", headerTooltip: "Аффинити к блоку (стремление его читать) - количество нажатий на пункт меню, обозначающий данный блок (нажатие на меню независимо от блока где в этот момент находится пользователь)  до разворота" },
      { autoHeaderHeight: true, wrapHeaderText: true, headerName: "Аффинити к блоку - количество раз где пролистывали все блоки до этого - вручную", field: "f210", headerTooltip: "Аффинити к блоку - количество раз где пролистывали все блоки до этого - вручную, без использования меню (менее 1 секунды), а на данном блоке задержались на более чем 1 секунду (при прокрутке вниз - до разворота)" },
      { autoHeaderHeight: true, wrapHeaderText: true, autoHeight: true, wrapText: true, headerName: "Разрез по utm_term", field: "statUtm", headerTooltip: "Разрез по utm_term" },
      { autoHeaderHeight: true, wrapHeaderText: true, autoHeight: true, wrapText: true, headerName: "Разрез по device", field: "statDevice", headerTooltip: "Разрез по device" },
      { autoHeaderHeight: true, wrapHeaderText: true, autoHeight: true, wrapText: true, headerName: "События на блоке до разворота", field: "events", headerTooltip: "События на блоке  до разворота" },
      { autoHeaderHeight: true, wrapHeaderText: true, autoHeight: true, wrapText: true, headerName: "События на блоке", field: "eventsAll", headerTooltip: "События на блоке" },
      
    ]);

    return {
      //rowData,
      colDefs,
    };
  },
  data() {
    return {
      clientId: '',
      dateStart: '',
      dateTimeStart: '', 
      dateEnd: '',
      dateTimeEnd: '',
      data: null,
      datePoints: [],
      dataPointsCurrent: '',
      rowData: [],
      requested: false,
      tokens: [],
      projects: [],
      projectCurrent: '',
      projectCurrentName: '',
      utmGroups: [],
      tokenCurrent: '',
      tokenCurrentName: '',
      utmCurrent: '',
      utmCurrentName: '',
      hostApi: process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : 'http://localhost:5001', 
    };
  },
  mounted() {
    let vm = this;
    console.log(process.env.VUE_APP_API_URL)
    // Устанавливаем значение dateEnd на сегодняшнюю дату
    vm.dateEnd = new Date().toISOString().substr(0, 10);
    vm.dateTimeEnd = new Date().toISOString();
    this.getProjects();
  },
  methods: {

    updateDateTimeStart() {
      if (this.dateStart) {
        this.dateTimeStart = new Date(this.dateStart).toISOString();
      } else {
        this.dateTimeStart = '';
      }
      this.dataPointsCurrent = '';
    },
    updateDateTimeEnd() {
      if (this.dateEnd) {
        this.dateTimeEnd = new Date(this.dateEnd).toISOString();
      } else {
        this.dateTimeEnd = '';
      }
      this.dataPointsCurrent = '';
    },
    changeDataPoints(e){

      const selectedDateOption = this.datePoints[e]
      if (selectedDateOption) {
        this.dateStart = selectedDateOption[0].split('T')[0];
        this.dateTimeStart = selectedDateOption[0];
        if (selectedDateOption[1] !== 'сегодня') {
          this.dateEnd = selectedDateOption[1].split('T')[0];
          this.dateTimeEnd = selectedDateOption[1];
        } else {
          this.dateEnd = new Date().toISOString().substr(0, 10);
          this.dateTimeEnd = new Date().toISOString();
        }        
      }
    },
    updateUtmGroupInput(e){
      const selectedUtm = e.target.value;

      const selectedOption = this.utmGroups.find(token => token.name === selectedUtm);
      console.log('updateUtmGroupInput', selectedOption)
      if (selectedOption) {
        const inputValue = selectedOption.id;
        this.utmCurrent = inputValue;
      } else {
        this.utmCurrent = '';
      }
      //this.getTokens();
    },
    updateProjectsInput(e){
      const selected= e.target.value;
      const selectedOption = this.projects.find(item => item.name === selected);
      if (selectedOption) {
        const inputValue = selectedOption.id;
        this.projectCurrent = inputValue;
        //add to localStorage
        localStorage.setItem('projectCurrent', inputValue);
        localStorage.setItem('projectCurrentName', selectedOption.name);
        //e.target.dataset.value = inputValue;
        this.getTokens();
      }
    },
    updateTokensInput(e) {//{{token.name}} | {{token.site}}
      const selectedToken = e.target.value;
      const selectedOption = this.tokens.find(token => token.name+' | '+token.site === selectedToken);
      if (selectedOption) {
        const inputValue = selectedOption.token;
        this.tokenCurrent = inputValue;
        //e.target.dataset.value = inputValue;
        this.getDatePoints();
        this.getUtmGroups()
        
      }

    },
    getProjects() {
      console.log("getProject");
      let vm = this;
      axios
        .post(vm.hostApi + '/api/v1/project/get', 
          {
            clientId: localStorage.getItem('clientId'),
          }
        )
        .then(function(response) {
              vm.projects = response.data.data;
              console.log('getProject', response.data.data)
              console.log('vm.projects', vm.projects)
              if (localStorage.getItem('projectCurrent')!= null){
                const tmpProject = localStorage.getItem('projectCurrent');
                const tmpOpt = vm.projects.find(item => item.id === tmpProject);
                if (tmpOpt) {
                  vm.projectCurrent = localStorage.getItem('projectCurrent');
                  vm.projectCurrentName = localStorage.getItem('projectCurrentName');
                  vm.getTokens();
                }
              }
        })
        .catch(function(error) {
              console.log(error);
        });
    },
    getTokens() {
      console.log("getTokens")
      let vm = this;
      axios
      .post(vm.hostApi + '/api/v1/token/get', 
        {
            clientId: localStorage.getItem('clientId'),
            projectId: this.projectCurrent,
        })
     .then(function(response) {
          vm.tokens = response.data;
          console.log(response.data)
        })
     .catch(function(error) {
          console.log(error);
      });
    },
    getDatePoints() {
      console.log("getDatePoints")
      let vm = this;
      axios
      .post(vm.hostApi + '/api/v1/token/getDatePoints', 
        {
          clientId: localStorage.getItem('clientId'),
          projectId: this.projectCurrent,
          token: this.tokenCurrent,
        })
      .then(function(response) {
          console.log(response.data)
          let data = response.data;
          let tmpArr = [];
          for (let i = 1; i < data.length; i++) {
            console.log('data[i].timestamp', data[i].timestamp)
            let tmpDate = new Date(data[i].timestamp).toISOString();
            console.log('tmpDate', tmpDate)

/*
            if (i == 0){
              tmpArr.push([null, tmpDate]);
              continue;
            }
*/
            if (i == data.length - 1){
              tmpArr.push([tmpDate, 'сегодня']);
              continue;
            }
            tmpArr.push([new Date(data[i-1].timestamp).toISOString(), tmpDate]);
          }

          vm.datePoints = tmpArr;
          console.log('datePoints', vm.datePoints)
        })
      .catch(function(error) {
          console.log(error);
        });
    },
    getUtmGroups() {
      console.log("getUtmGroups")
      let vm = this;
      axios
      .post(vm.hostApi + '/api/v1/utmGroup/get', 
        {
          clientId: localStorage.getItem('clientId'),
          projectId: this.projectCurrent,
        })
      .then(function(response) {
        console.log(response.data.data)
          vm.utmGroups = response.data.data;
        })
      .catch(function(error) {
          console.log(error);
        });
    },
    getBlockName(index) {
      
      if (index < 0) {
        index = 0;
      }
      
      
      const keys = Object.keys(this.data);
      if (index > keys.length-1) {
        index = keys.length-1
      }
      //console.log(index, keys.length, keys[index]);
      if (index >= 0 && index < keys.length) {
        //console.log('+');
        return keys[index];
      } else {
        //console.log('-');
        return ''; // или другое значение, если предыдущего блока нет
      }
    },
    fetchData() {
      console.log(this.dateStart, this.dateEnd, 'utmCurrent', this.utmCurrent);
      axios.post(this.hostApi+'/api/v1/getstream', 
        {
            clientId: localStorage.getItem('clientId'),
            token: this.tokenCurrent,
            utmGroupId: this.utmCurrent,
            dateStart: this.dateTimeStart,
            dateEnd: this.dateTimeEnd 
        })
        .then(response => {
          this.data = response.data;
          console.log(response.data);
          this.requested = true;
          let data = response.data;
          let arr = [];
          let index = 0;
          for (let name in data) {
            console.log('index', index);
            console.log('name', name);
            console.log(data);
            let obj = {
              f10: name,
              dubleBlock: data[name].dubleBlock,
              f20: data[name].down,
              f30: data[name].up,
              f35: data[name].upFirst,
              f40: data[name].left,
              f50: data[name].upFirst+data[name].left,
              f60: data[name].countToRead,
              f70: data[name].countToView,
              f80: (data[this.getBlockName(index)].countToView / data[this.getBlockName(0)].countToView*100).toFixed(2),
              f90: ((data[name].upFirst + data[name].left)/data[name].countToView*100).toFixed(2),
              f100: data[this.getBlockName(index)].countToView - data[this.getBlockName(index+1)].countToView,
              f110: (data[name].countToRead/data[name].countToView*100).toFixed(2),
              f120: data[name].scrolledBlockWithoutMenu,
              f130: data[name].scrolledBlockFromMenu,
              f140: data[name].arrSecondView,
              f150: data[name].lengthArrSecondView,
              f160: data[name].avgSecondView ? data[name].avgSecondView.toFixed(0) : 0,
              f170: data[name].filteredArrSecondView,
              f180: data[name].lengthFilteredArrSecondView,
              f190: data[name].avgFilteredSecondView ? data[name].avgFilteredSecondView.toFixed(0) : 0,
              f200: data[name].clickToMenu,
              statUtm: objectToHtml(data[name].utm),
              statDevice: objectToHtml(data[name].device),
              events: objectToHtmlEvents(data[name].events),
              eventsAll: objectToHtmlEvents(data[name].eventsAll),
            }
            arr.push(obj);
            index++
          }
          this.rowData = arr;
          console.log(this.rowData);
        })
        .catch(error => {
          console.error('Ошибка при запросе данных:', error);
        });
    }
  },
};


function objectToHtml(obj) {
  let htmlString = '';

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      let entry = `${key}; `;
      let values = [];

      for (let subKey in obj[key]) {
        if (obj[key].hasOwnProperty(subKey)) {
          values.push(`${subKey}:${obj[key][subKey]}`);
        }
      }

      entry += values.join(', ');
      htmlString += `${entry}\n`;
    }
  }

  return htmlString;
}
function objectToHtmlEvents(obj) {
  let htmlString = '';

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      let entry = ``;
      let values = [];

      values.push(`${key}:${obj[key]}`);

      entry += values.join(', ');
      htmlString += `${entry}\n`;
    }
  }

  return htmlString;
}
</script>