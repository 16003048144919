<template>
  <BlankPage/>
</template>

<script>
import IndexPage from './components/IndexPage.vue'
import HomePage from './components/HomePage.vue'
import BlankPage from './components/BlankPage.vue'
import axios from "axios";
// Получение токена из localStorage
const token = localStorage.getItem('token');
// Добавление токена в заголовок Authorization для всех запросов
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

export default {
  name: 'App',
  components: {
    BlankPage
  }
}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
