<template>
  <Header/>
  <div>
    <h1>Смена пароля</h1>
    <form @submit.prevent="changePassword">
      <label>Email:</label>
      <input type="login" v-model="login" required><br>
      <label>Новый пароль:</label>
      <input type="password" v-model="newPassword" required><br>
      <button type="submit">Сменить пароль</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import Header from '@/components/Header.vue';
export default {
  components: {
    Header,
  },
  data() {
    return {
      newPassword: '',
      hostApi: process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : 'http://localhost:5001', 
    };
  },
  methods: {
    async changePassword() {
      try {
        const response = await axios.post(this.hostApi+'/api/v1/change-password', {
          login: this.login,
          newPassword: this.newPassword
        });
        console.log(response.data);
      } catch (error) {
        console.error(error.response.data);
      }
    }
  }
};
</script>