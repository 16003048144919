<template>
    <div class="blocks-page row">
      <div class="cards-column col-md-6">
        <div class="container">
          <div class="row">
            <div class="col-md-7">
              <button class="btn btn-primary mb-3" @click="addEvent">Добавить событие</button>
            </div>
          </div>
        </div>


        
        <ul class="list-group mb-3">
          <li class="list-group-item d-flex justify-content-between align-items-center" v-for="event in events" :key="event.id">
            {{ event.value }}
            <div>
              <button class="btn btn-sm btn-secondary me-2" @click="editEvent(event)">Редактировать</button>
              <button class="btn btn-sm btn-danger" @click.stop="confirmDeleteEvent(event.id)">X</button>
            </div>
          </li>
        </ul>
      </div>
    <div class="card-details col-md-6 block-edit-card" v-if="selectedEvent">
      <button class="btn-close" @click="closeEventDetails"></button>
    
      <div class="mb-3">
        <label class="form-label">Класс события:</label>
        <select class="form-select" v-model="selectedEvent.class">
          <option value="EventListener">EventListener</option>
          <option value="dataLayer">dataLayer</option>
        </select>
      </div>
      <div v-if="selectedEvent.class === 'dataLayer'">
        <div class="mb-3">
          <label class="form-label">Название события</label>
          <input class="form-control" v-model="selectedEvent.value" placeholder="Отправка формы на верхнем блоке"/>
        </div>
        <div class="mb-3">
          <label class="form-label">Тип события (для удобной классификации):</label>
          <input class="form-control" v-model="selectedEvent.type" placeholder="form"/>
        </div>
        <div class="mb-3">
          <label class="form-label">Event Action:</label>
          <input class="form-control" v-model="selectedEvent.eventAction" placeholder="/tilda/form285833823/submitted"/>
        </div>
      </div>
      <div v-if="selectedEvent.class === 'EventListener'">
        <div class="mb-3">
          <label class="form-label">Название события</label>
          <input class="form-control" v-model="selectedEvent.value" placeholder="Клик по кнопке"/>
        </div>
        <div class="mb-3">
          <label class="form-label">Тип события (для удобной классификации):</label>
          <input class="form-control" v-model="selectedEvent.type" placeholder="click menu"/>
        </div>
        <div class="mb-3">
          <label class="form-label">Событие eventListener:</label>
          <select class="form-select" v-model="selectedEvent.eventName">
            <option v-for="eventName in eventListenerNames"
              :key="eventName.value" 
              :value="eventName.value"
            >{{ eventName.value }} | {{ eventName.name }} 
            </option>
          </select>
        </div>
        <div class="mb-3">
          <label class="form-label">По какому параметру определяем событие:</label>
          <select class="form-select" v-model="selectedEvent.target">
            <option value="elementClass">elementClass</option>
            <option value="elementSelectorAll">elementSelectorAll</option>
            <option value="elementId">elementId</option>
          </select>
        </div>
        <div class="mb-3">
          <label class="form-label">Значение параметра:</label>
          <input class="form-control" v-model="selectedEvent.targetName" placeholder="t396__elem tn-elem tn-elem__6358205461693830037919" />
        </div>
        <div class="mb-3">
          <button class="btn btn-light mt-3" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse" aria-expanded="true" aria-controls="panelsStayOpen-collapse">Якорная ссылка</button>
        </div>
        <div id="panelsStayOpen-collapse" class="accordion-collapse collapse close mb-3" aria-labelledby="panelsStayOpen-heading">
          <label class="form-label">Прокрутка на блок по клику (Опционально):</label>
          <input class="form-control" v-model="selectedEvent.directionTo"  placeholder="Верхний блок"/>
        </div>
      </div>
      <button class="btn btn-success" @click="saveEventDetails">Сохранить</button>
    
    </div>
  </div>
</template>


<script>
import { ref } from 'vue';
import axios from "axios";
// Получение токена из localStorage
const token = localStorage.getItem('token');
// Добавление токена в заголовок Authorization для всех запросов
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'EventsPage',
  props: {
    events: {
      type: Array,
      required: true
    },
    tokenCurrent: {
      type: String,
    },
    projectCurrent: {
      type: String,
    }
  },
  setup(props, { emit }) {
    const hostApi = ref(process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : 'http://localhost:5001');

    //const events = ref([]);
    const selectedEvent = ref(null);

    const addEvent = () => {
      selectedEvent.value = {
        id: uuidv4(),
        class: 'EventListener',
        value: '', //название события 'Клик по кнопке'
        type: '', //тип события 'click button', для классификации
        eventAction: '',
        eventName: '',
        target: '', //например elementSelectorAll
        targetName: '', //'#rec285833857 button'
        directionTo: ''
      };
    };
    const closeEventDetails = () => {
      selectedEvent.value = null;
    };
    const editEvent = (event) => {
      selectedEvent.value = { ...event };

    };
    const confirmDeleteEvent = (id) => {
      if (confirm('Вы уверены, что хотите удалить это событие?')) {
        deleteEvent(id);
      }
    };
    const deleteEvent = (id) => {
      let updatedEvents = [...props.events];
      //let updatedEvents = [...props.events];
      const index = props.events.findIndex(event => event.id === id);
      
      if (index !== -1) {
        updatedEvents.splice(index, 1);
        //props.events.value.splice(index, 1);
        console.log('deleteEvent')
        axios
        .post(hostApi.value + '/api/v1/block/deleteEvent', {
          id: id,
          clientId: localStorage.getItem('clientId'),
          projectId:  props.projectCurrent,
          tokenId: props.tokenCurrent,
        })
        .then(function(response) {
            console.log(response.data)
        })
        .catch(function(error) {
            console.log(error);
        });
        emit('update:events', updatedEvents);
      }
      

/*
      const index = events.value.indexOf(event);
      if (index !== -1) {
        events.value.splice(index, 1);
        emit('delete', event);
      }
      */
    };

    const saveEventDetails = async() => {
      if (selectedEvent.value.class == 'dataLayer') {

        selectedEvent.value.eventName = 'pageView';
        selectedEvent.value.target = null;
        selectedEvent.value.targetName = null;

        if (!selectedEvent.value.eventAction ||!selectedEvent.value.value || !selectedEvent.value.type) {

          //console.log('dataLayer', selectedEvent.value)
          alert('Пожалуйста, заполните все поля.');
          return;
        }
      } else if (selectedEvent.value.class == 'EventListener') {

        selectedEvent.value.eventAction = null;

        if (!selectedEvent.value.target ||!selectedEvent.value.targetName ||!selectedEvent.value.value || !selectedEvent.value.type || !selectedEvent.value.eventName) {
          //console.log('EventListener', selectedEvent.value)
          alert('Пожалуйста, заполните все поля.');
          return;
        }
      }
      const index = props.events.findIndex(e => e.id === selectedEvent.value.id);
      try {
            let data = {...selectedEvent.value };
            data.clientId = localStorage.getItem('clientId');
            data.projectId = props.projectCurrent;
            data.tokenId = props.tokenCurrent;
            const response = await axios.post(hostApi.value+'/api/v1/block/saveEvent', data);
          
        if (index !== -1) {

          const updatedEvents = [...props.events];
          updatedEvents[index] = selectedEvent.value;

          emit('update:events', updatedEvents);

          props.events[index] = { ...selectedEvent.value };

          
        } else {
          props.events.push({ ...selectedEvent.value });
          emit('save', selectedEvent.value);
          selectedEvent.value = null;
        }
      } catch (error) {
        console.error('Ошибка:', error);
        alert('Не удалось сохранить данные. Попробуйте еще раз.');
      }
    };
    //"click", "copy", "dblclick", "mousedown", "mouseup", "contextmenu", "mouseout", "mousewheel", "mouseover", "focus", "blur", "change", "submit"
    const eventListenerNames = [
      {value: 'click', name: 'Клик'},
      {value: 'copy', name: 'Копирование'},
      {value: 'input', name: 'Ввод'},
      {value: 'change', name: 'Изменение'},
      {value: 'dblclick', name: 'Двойной клик'},
      /*
      {value: 'mousedown', name: 'Нажатие кнопки мыши'},
      {value: 'mouseup', name: 'Отпускание кнопки мыши'},
      {value: 'mouseout', name: 'Указатель перемещается за пределы элемента'},
      {value: 'mouseup', name: 'Контекстное меню'},
      {value:'mouseout', name: 'Указатель перемещается за пределы элемента'},
      {value:'mousewheel', name: 'Прокрутка'},
      {value: 'mouseover', name: 'Указатель перемещается на элемент'},,
      {value: 'mouseenter', name: 'Указатель перемещается на элемент'},
      {value: 'mouseleave', name: 'Указатель покидает элемент'},
*/
      {value: 'focus', name: 'Фокус'},
      {value: 'blur', name: 'Снятие фокуса'},
      {value: 'paste', name: 'Некий контент вставлен в элемент'},
      {value: 'play', name: 'Воспроизведение начато'},
      {value: 'pause', name: 'Воспроизведение остановлено'},
    ];

    return { selectedEvent, addEvent, editEvent, deleteEvent, saveEventDetails, confirmDeleteEvent, closeEventDetails, eventListenerNames };
  }
};
</script>

<style>
.blocks-page {
  display: flex;
}
.block-edit-card {
  margin-top: 7% !important;
  margin-bottom: 1% !important;;
}

.cards-column {
  width: 30%;
  border-right: 1px solid #ddd;
}
.card-pointer {
  cursor: pointer;
}
.card {
  text-align: left;
}
.card-details {
  padding: 10px;
  width: 70%;
  position: fixed;
  right: 0;
  top: 0;
  height: 90%;
  overflow-y: auto;
  background: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
}
.card-details > div {
  margin-bottom: 10px;
}
.btn-close {
  position: absolute;
  right: 10px;
  top: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
}
</style>
