<template>
  <div class="blocks-page row">
    <div class="cards-column col-md-6">
      <div class="container">
        <div class="row">
          <div class="col-md-7">
            <button class="btn btn-primary mt-3" @click="addNewCard" :disabled="hasUnsavedCard">Добавить карточку</button>
          </div>
          <div class="col-md-5">
            <button class="btn btn-light mt-3" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse" aria-expanded="true" aria-controls="panelsStayOpen-collapse">Разворот блоков</button>
          </div>
        </div>
      </div>
      <div v-if="cards.length > 0">
        <draggable
          :list="cards"
          item-key="id"
          @end="onCardsChange"
          :auto-scroll="true"
          :scroll-sensitivity="100"
          :scroll-speed="100"
          :animation="400"
          :force-fallback="true"
        >
          <template #item="{ element, index }">
            <div
              :class="['card', 'mb-3', { 'border-info': isSelected(element), 'border-light': !isSelected(element), 'bg-light': true }]"
              @click="selectCard(element)"
            >
              <div class="card-body">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="panelsStayOpen-heading">
                    <div class="row">
                      <div class="col-md-10">
                        <div class="card-title text-center">{{ element.weight }}) {{ element.value }}</div>
                      </div>
                      <div class="col-md-2">
                        <button class="btn btn-danger" @click.stop="confirmDeleteCard(element.id)">X</button>
                      </div>
                    </div>
                  </h2>
                  <div id="panelsStayOpen-collapse" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-heading">
                    <div class="accordion-body">
                      <div class="row border-bottom" style="display: none;">
                        <div class="col-md-4">
                          <strong>Type</strong>
                        </div>
                        <div class="col-md-5">
                          <div class="card-text">{{ element.type }}</div>
                        </div>
                      </div>
                      <template v-for="item in element.item" :key="index">
                        <div class="row border-bottom">
                          <div class="col-md-4">
                            <strong>Таргет</strong>
                          </div>
                          <div class="col-md-5">
                            <div class="card-text">{{ item.target }}</div>
                          </div>
                        </div>
                        <div class="row border-bottom">
                          <div class="col-md-4">
                            <strong>Значение таргет</strong>
                          </div>
                          <div class="col-md-5">
                            <div class="card-text">{{ item.targetName }}</div>
                          </div>
                        </div>
                      </template>
                      <!--div class="row border-bottom">
                        <div class="col-md-4">
                          <strong>Просмотр при</strong>
                        </div>
                        <div class="col-md-5">
                          <div class="card-text">{{ element.threshold * 100 }}%</div>
                        </div>
                      </div-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </draggable>
      </div>
    </div>
    <div class="card-details col-md-6 block-edit-card" v-if="selectedCard">
      <button class="btn-close" @click="closeCardDetails"></button>
      <div class="mb-3">
        <label class="form-label">Название блока:</label>
        <input class="form-control" v-model="selectedCard.value" />
      </div>
      <div class="mb-3" style="display: none;">
        <label class="form-label">Тип:</label>
        <input class="form-control" v-model="selectedCard.type" />
      </div>
      <template v-for="(cardItem, index) in selectedCard.item">
        <div class="card card-pointer">
            <div class="card-body">
                

              <div class="col-md-2">
                  <button v-if="selectedCard.item.length > 1"  class="btn btn-danger" @click.stop="confirmDeleteCardItem(selectedCard.id, index)">X</button>
              </div>

              <div class="mb-3">
                <label class="form-label">По какому параметру определяем блок:</label>
                <select class="form-select" v-model="cardItem.target">
                  <option value="elementId">elementId</option>
                  <option value="elementClass">elementClass</option>
                  <option value="elementSelectorAll">elementSelectorAll</option>
                </select>
              </div>
              <div class="mb-3">
                <label class="form-label">Значение параметра:</label>
                <input class="form-control" v-model="cardItem.targetName" />
              </div>
              <!--div class="mb-3">
                <label class="form-label">Процент видимости на экране для регистрации события:</label>
                <input type="number" class="form-control" v-model="cardItem.threshold" step="0.01" min="0.01" max="1" />
              </div-->
            </div>
          </div>
      </template>
      <div class="mb-3">
        <button class="btn btn-primary" @click="addNewItemToCard">Добавить блок</button>
      </div>
      <div class="mb-3">
        <button class="btn btn-success" @click="saveCardDetails">Сохранить</button>
      </div>
      
      
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import draggable from 'vuedraggable';
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
// Получение токена из localStorage
const token = localStorage.getItem('token');
// Добавление токена в заголовок Authorization для всех запросов
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

export default {
  name: 'BlocksPage',
  components: { draggable },
  props: {
    cards: {
      type: Array,
      required: true
    },
    tokenCurrent: {
      type: String,
    },
    projectCurrent: {
      type: String,
    }
  },
  data() {
    return {
      //hostApi: process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : 'http://localhost:5001', 
    };
  },
  setup(props, { emit }) {
    const hostApi = ref(process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : 'http://localhost:5001');
    
    const selectedCard = ref(null);
    const hasUnsavedCard = ref(false); // Добавляем состояние для отслеживания несохраненной карточки

    const selectCard = (card) => {
      selectedCard.value = { ...card };
    };

    const isSelected = (card) => {
      return selectedCard.value && selectedCard.value.id === card.id;
    };

    watch(
      () => props.cards,
      (newCards) => {
        if (selectedCard.value) {
          const updatedCard = newCards.find(card => card.id === selectedCard.value.id);
          if (updatedCard) {
            selectedCard.value = { ...updatedCard };
          }
        }
      },
      { deep: true }
    );

    const onCardsChange = (event) => {
      emit('reorder', event);
      let updatedCards = [...props.cards];
      updatedCards = updateWeightAllCards(updatedCards);
      emit('update:cards', updatedCards);
    };

    const saveCardDetails = async () => {
      
      for (let i = 0; i < selectedCard.value.item.length; i++) {
        if (!selectedCard.value.item[i].target || !selectedCard.value.item[i].targetName ) {
          alert('Пожалуйста, заполните все поля.');
          return;
        }
      }
      if ( !selectedCard.value.type || !selectedCard.value.value ) {
        alert('Пожалуйста, заполните все поля.');
        return;
      }
      const cardIndex = props.cards.findIndex(card => card.id === selectedCard.value.id);
      console.log('Сохраняем карточку:', cardIndex, props.cards);
      console.log('Сохраняем карточку:', cardIndex, selectedCard.value);
      if (cardIndex !== -1) {
          try {
            let data = {...selectedCard.value };
            data.clientId = localStorage.getItem('clientId');
            data.projectId = props.projectCurrent;
            data.tokenId = props.tokenCurrent;
            const response = await axios.post(hostApi.value+'/api/v1/block/saveCard', data);
            
            const updatedCards = [...props.cards];
            updatedCards[cardIndex] = selectedCard.value;

            emit('update:cards', updatedCards);
            selectedCard.value = null; // Скрываем блок после успешного сохранения
            hasUnsavedCard.value = false; // Обновляем состояние после сохранения
        } catch (error) {
          console.error('Ошибка:', error);
          alert('Не удалось сохранить данные. Попробуйте еще раз.');
        }
      }
    };

    const addNewItemToCard = async () => {
      for (let i = 0; i < selectedCard.value.item.length; i++) {
        if (!selectedCard.value.item[i].target || !selectedCard.value.item[i].targetName ) {
          alert('Пожалуйста, заполните все поля.');
          return;
        }
      }
      if ( !selectedCard.value.type || !selectedCard.value.value ) {
        alert('Пожалуйста, заполните все поля.');
        return;
      }
      selectedCard.value.item.push({
        target: 'elementId',
        targetName: '',
      });
      const cardIndex = props.cards.findIndex(card => card.id === selectedCard.value.id);
      const updatedCards = [...props.cards];
      updatedCards[cardIndex] = selectedCard.value;

      emit('update:cards', updatedCards);
    };
    const addNewCard = () => {
      const newCard = {
        id: uuidv4(), // Generate a new id for the card
        value: `Блок ${props.cards.length + 1}`,
        item: [
          {
            target: 'elementId',
            targetName: '',
          }
        ],
        type: 'block view',
        //threshold: 0.01,
        weight: props.cards.length + 1,
      };
      
      const updatedCards = [...props.cards, newCard];
      
      emit('update:cards', updatedCards);
      selectCard(newCard);
      
      hasUnsavedCard.value = true; // Обновляем состояние после добавления новой карточки
    };

    const confirmDeleteCard = (id) => {
      if (confirm('Вы уверены, что хотите удалить эту карточку?')) {
        deleteCard(id);
      }
    };
    const confirmDeleteCardItem = (id, indexItem) => {
      
      if (confirm('Вы уверены, что хотите удалить этот блок?')) {
        deleteCardItem(id, indexItem);
      }
    };
    const deleteCardItem = (id, indexItem) => {
      let updatedCards = [...props.cards];
      console.log(updatedCards)
      //const index = updatedCards.findIndex(card => card.id === id);
      //const deletedCardUuid = updatedCards[index]?.id;
      selectedCard.value.item.splice(indexItem, 1);

      const cardIndex = props.cards.findIndex(card => card.id === id);
      updatedCards[cardIndex] = selectedCard.value;

      emit('update:cards', updatedCards);

      //updatedCards = updateWeightAllCards(updatedCards); // Обновляем веса после удаления карточки
      /*
      axios
        .post(hostApi.value + '/api/v1/block/deleteCardItem', {
          id: id,
          clientId: localStorage.getItem('clientId'),
          projectId:  props.projectCurrent,
          tokenId: props.tokenCurrent,
        })
        .then(function(response) {
            console.log(response.data)
        })
        .catch(function(error) {
            console.log(error);
        });
      */
      emit('update:cards', updatedCards);
      /*
      
      if (selectedCard.value && selectedCard.value.id === deletedCardUuid) {
        selectedCard.value = null;
        hasUnsavedCard.value = false; // Обновляем состояние после удаления карточки
      }
      */
    };
    const deleteCard = (id) => {
      let updatedCards = [...props.cards];
      const index = updatedCards.findIndex(card => card.id === id);
      const deletedCardUuid = updatedCards[index]?.id;
      updatedCards.splice(index, 1);
      updatedCards = updateWeightAllCards(updatedCards); // Обновляем веса после удаления карточки
      axios
        .post(hostApi.value + '/api/v1/block/deleteCard', {
          id: id,
          clientId: localStorage.getItem('clientId'),
          projectId:  props.projectCurrent,
          tokenId: props.tokenCurrent,
        })
        .then(function(response) {
            console.log(response.data)
        })
        .catch(function(error) {
            console.log(error);
        });

      emit('update:cards', updatedCards);
      
      
      if (selectedCard.value && selectedCard.value.id === deletedCardUuid) {
        selectedCard.value = null;
        hasUnsavedCard.value = false; // Обновляем состояние после удаления карточки
      }
    };

    const closeCardDetails = () => {
      selectedCard.value = null;
      hasUnsavedCard.value = false; // Обновляем состояние после закрытия деталей карточки
    };

    const updateWeightAllCards = (updatedCards) => {
      let arrIdAndWeight = [];
      for (let i = 0; i < updatedCards.length; i++) {
        updatedCards[i].weight = i + 1;
        arrIdAndWeight.push({id: updatedCards[i].id, weight: updatedCards[i].weight});
      }
      updateWeightRoute(arrIdAndWeight);
      return updatedCards;
    }

    const updateWeightRoute = (arr) => {
      axios
        .post(hostApi.value + '/api/v1/block/updateWeight', {
          arr: arr,
          clientId: localStorage.getItem('clientId'),
          projectId:  props.projectCurrent,
          tokenId: props.tokenCurrent,
        })
        .then(function(response) {
            //console.log(response.data)
        })
        .catch(function(error) {
            console.log(error);
        });
    };


    return {
      selectedCard,
      selectCard,
      isSelected,
      onCardsChange,
      saveCardDetails,
      addNewItemToCard,
      addNewCard,
      confirmDeleteCard,
      confirmDeleteCardItem,
      deleteCard,
      deleteCardItem,
      closeCardDetails,
      hasUnsavedCard // Возвращаем новое состояние
    };
  }
};
</script>

<style>
.blocks-page {
  display: flex;
}
.block-edit-card {
  margin-top: 7% !important;
  margin-bottom: 1% !important;;
}

.cards-column {
  width: 30%;
  border-right: 1px solid #ddd;
}

.card-pointer {
  cursor: pointer;
}
.card {
  text-align: left;
}
.card-details {
  padding: 10px;
  width: 70%;
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  overflow-y: auto;
  background: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
}
.card-details > div {
  margin-bottom: 10px;
}
.btn-close {
  position: absolute;
  right: 10px;
  top: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
}
</style>
