<template>
<router-view></router-view>
</template>

<script>

export default {
  data() {
    return {
      hostApi: process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : 'http://localhost:5001', 
    };
  },
};
</script>