
<template>
  <Header/>
  <div>
    <div>
      <label for="project">Проект</label>
      <input class="setList" list="projectList"  @change="updateProjectsInput" v-model="projectCurrentName" :data-value="projectCurrent" type="text" id="project" placeholder="Название проекта" />
      <datalist id="projectList">
        <option v-for="item in projects" 
          :key="item.id" 
          :data-value="item.id"
          :value="item.name"> 
        </option>
      </datalist>


      <label for="groupUtm">Группа УТМ</label>
      <input class="setList" list="groupUtmList" @input="inputUtmGroupInput"  v-model="groupUtmCurrentName" :data-value="groupUtmCurrent" type="text" id="groupUtm" placeholder="Название группы" />
      <input type="button" value="Добавить группу" @click="addUtmGroup" v-if="createNewGroupButton">
      <datalist id="groupUtmList">
        <option v-for="item in utmGroups" 
          :key="item.id" 
          :data-value="item.id"
          :value="item.name"> 
        </option>
      </datalist>


      <div class="input">
          <span class="input-group-addon">Содержит</span>
          <input class="form-control" @change="onChangeTags" @input="onInputTags" id="list-tags">
        </div>
    </div>
    <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
    
  </div>
  <link href="https://cdn.jsdelivr.net/npm/@yaireo/tagify/dist/tagify.css" rel="stylesheet" type="text/css" />
</template>


<script>
/*

*/
import { ref } from 'vue';
import axios from "axios";
// Получение токена из localStorage
const token = localStorage.getItem('token');
// Добавление токена в заголовок Authorization для всех запросов
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

//import Tagify from '@yaireo/tagify'
import Tagify from '@yaireo/tagify'
//import './assets/tagify.css'
import Header from '@/components/Header.vue';

export default {
  components: {
   Header,
  },
  data() {
    return {
      clientId: '',
      dateStart: '',
      dateEnd: '', // Установка значения по умолчанию
      data: null,
      rowData: [],
      requested: false,
      tokens: [],
      utmGroups: [],
      createNewGroupButton: false,
      projectCurrent: '',
      projectCurrentName: '',
      groupUtmCurrent: '',
      tagsArr: [],
      groupUtmCurrentName: '',
      projects: [],
      utmCurrent: '',
      utmCurrentName: '',
      hostApi: process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : 'http://localhost:5001', 
    };
  },
  mounted() {
    let vm = this;
    let inputListTags = document.getElementById("list-tags");
    this.tagify = new Tagify(inputListTags, {whitelist:[]});
    this.tagify.on('input', this.onInputTags);
    this.getProject();

  },
  methods: {
    async addUtmGroup(){
      this.createNewGroupButton = false;
      if(this.groupUtmCurrentName == '' || this.groupUtmCurrentName == null){
        alert('Название группы не может быть пустым');
        return;
      }
      console.log('addUtmGroup', this.groupUtmCurrentName);
      
      await axios
        .post(this.hostApi + '/api/v1/utmgroup/add', {
          name: this.groupUtmCurrentName,
          clientId: localStorage.getItem('clientId'),
          projectId: this.projectCurrent,
        })
        .then(res => {
           console.log('addUtmGroup', res.data);
           this.groupUtmCurrent = res.data.data.id;
           this.createNewGroupButton = false;
 
        })
      this.getUtmGroups()
      
    },
    onInputTags( e ){
      let vm = this;
      console.log("onInputTags");
      var tag = e.detail.value
      this.tagify.whitelist = null // сброс подсказок
      if (e.detail.value.length == 0) return;
      // показать анимацию загрузки и скрыть подсказки
      this.tagify.loading(true)//.dropdown.hide()
      axios
        .post(this.hostApi + '/api/v1/utm/searchUtm', 
          {
            clientId: localStorage.getItem('clientId'),
            projectId: this.projectCurrent,
            q: tag
          }
        )
        .then(function(response) {
           console.log(response.data.data);
           vm.tagify.whitelist = response.data.data; // получаем данные подсказок
           vm.tagify.loading(false)//.dropdown.show(tag)
        })
        .catch(function(error) {
              console.log(error);
        });

    },
    
    onChangeTags(e){
      console.log("onChangeTags");

      let tags = [];
      try {
        tags = JSON.parse(e.target.value)
        console.log('tags', tags);
      } catch (err) {	 }
      console.log("tagif");
      //console.log("tags", tags, typeof tags);
      //console.log("this.tagsArr", this.tagsArr);
      const tagsForSave = tags.filter(x => !this.tagsArr.some(y => x.value === y.value));
      const tagsForRemove = this.tagsArr.filter(x => !tags.some(y => x.value === y.value));
      //console.log("tagsForSave", tagsForSave);
      //console.log("tagsForRemove", tagsForRemove);
      this.tagsArr = tags;
      
      let vm = this;

      if (tagsForSave.length > 0){
        console.log("tagsForSave", tagsForSave);
        vm.saveTags(tagsForSave);
      }
      if (tagsForRemove.length > 0){
        console.log("tagsForRemove", tagsForRemove);
        vm.removeTags(tagsForRemove)
      }

       //получаем начальные данные поиска с сервера
      
      
    },
    saveTags(tagsForSave){
      console.log("saveTags");
      axios
        .post(this.hostApi + '/api/v1/utmgroup/addutm', 
          {
            clientId: localStorage.getItem('clientId'),
            utmGroupId: this.groupUtmCurrent,
            utms: tagsForSave
          }
        )
        .then(function (response) {
          //console.log("saveTags", tags);
          //console.log("response", response);
        })
    },
    removeTags(tagsForRemove){
      console.log("removeTags");

      let vm = this;

      axios
        .post(this.hostApi + '/api/v1/utmgroup/removeutm', {
          clientId: localStorage.getItem('clientId'),
          utms: tagsForRemove,
          utmGroupId: this.groupUtmCurrent
        })
        .then(function (response) {

        })
    },
    inputUtmGroupInput(e){
      const selectedUtm = e.target.value;
      console.log('inputUtmGroupInput');
      const selectedOption = this.utmGroups.find(item => item.name === selectedUtm);
      if (selectedOption) {
        const inputValue = selectedOption.id;
        this.groupUtmCurrent = inputValue;
        //e.target.dataset.value = inputValue;
        this.getUtmsInGroups()
        this.createNewGroupButton = false;
      } else {
        console.log('inputUtmGroupInput FALSE');
        this.tagsArr = [];
        this.tagify.removeAllTags();
        this.createNewGroupButton = true;
      }
    },
    updateProjectsInput(e) {//{{token.name}} | {{token.site}}
      const selected = e.target.value;
      const selectedOption = this.projects.find(item => item.name === selected);
      if (selectedOption) {
        const inputValue = selectedOption.id;
        this.projectCurrent = inputValue;
        localStorage.setItem('projectCurrent', inputValue);
        localStorage.setItem('projectCurrentName', selectedOption.name);
        //e.target.dataset.value = inputValue;
        this.getUtmGroups()
      }

    },
    getProject() {
      console.log("getProject");
      let vm = this;
      axios
        .post(vm.hostApi + '/api/v1/project/get', 
          {
            clientId: localStorage.getItem('clientId'),
          }
        )
        .then(function(response) {
              vm.projects = response.data.data;
              console.log('getProject', response.data.data)
              console.log('vm.projects', vm.projects)
              if (localStorage.getItem('projectCurrent')!= null){
                const tmpProject = localStorage.getItem('projectCurrent');
                const tmpOpt = vm.projects.find(item => item.id === tmpProject);
                if (tmpOpt) {
                  vm.projectCurrent = localStorage.getItem('projectCurrent');
                  vm.projectCurrentName = localStorage.getItem('projectCurrentName');
                  vm.getUtmGroups();
                }
              }
        })
        .catch(function(error) {
              console.log(error);
        });
    },
    getUtmGroups() {
      let vm = this;
      axios
      .post(vm.hostApi + '/api/v1/utmGroup/get', 
        {
          clientId: localStorage.getItem('clientId'),
          projectId: this.projectCurrent,
        })
      .then(function(response) {
        console.log(response.data.data)
          vm.utmGroups = response.data.data;
        })
      .catch(function(error) {
          console.log(error);
        });
    },
    getUtmsInGroups() {
      let vm = this;
      axios
      .post(vm.hostApi + '/api/v1/utmGroup/getUtm', 
        {
          clientId: localStorage.getItem('clientId'),
          utmGroupId: this.groupUtmCurrent,
        })
      .then(function(response) {
        console.log(response.data.data)
          vm.tagsArr = response.data.data;
          vm.tagify.addTags(vm.tagsArr);
        })
      .catch(function(error) {
          console.log(error);
        });
    },
  }
};
</script>

<style>
.setList {
  width: 30%;
}
</style>