import * as VueRouter from 'vue-router'; // alternative ways of importing

import HomePage from './components/HomePage.vue';
import IndexPage from './components/IndexPage.vue';
import BlockStat from './components/BlockStat.vue';
import BlockStatXlsx from './components/BlockStatXlsx.vue';
import GroupUtm from './components/GroupUtm.vue';
import Login from './components/Login.vue';
import Register from './components/Register.vue';
import UserPage from './components/UserPage.vue';
import NotFound from './components/NotFound.vue';
import BlockAndEvent from './components/cabinet/BlockAndEvent.vue';

const routes = [
  
  { path: '/index', component: IndexPage },
  { path: '/', component: HomePage, meta: { requiresAuth: true } },
  { path: '/login', component: Login },
  { path: '/register', component: Register },
  { path: '/user', component: UserPage, meta: { requiresAuth: true } },
  { path: '/blockStat', component: BlockStat, meta: { requiresAuth: true } },
  { path: '/blockStatXlsx', component: BlockStatXlsx, meta: { requiresAuth: true } },
  { path: '/groupUtm', component: GroupUtm, meta: { requiresAuth: true } },
  { path: '/cabinet/', component: BlockAndEvent, meta: { requiresAuth: true } },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },
];

const router = VueRouter.createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: VueRouter.createWebHashHistory(),
  routes, // short for `routes: routes`
})

const hostApi = process.env.VUE_APP_API_URL

router.beforeEach(async (to, from, next) => {
  console.log('beforeEach')
  if (to.matched.some(record => record.meta.requiresAuth)) {
  //if (to.meta.requiresAuth) {
    try {
      console.log('requiresAuth')
      if (localStorage.getItem('token')){
        next();
      } else {
        next('/index');
      }
      /*
      const response = await axios.get(hostApi+'/api/v1/check-auth', {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
      });*/
      // Если пользователь аутентифицирован, переходим на запрошенный маршрут
      
    } catch (error) {
      // Если пользователь не аутентифицирован, перенаправляем на страницу логина
      next('/index');
    }
  } else {
    // Для всех остальных маршрутов продолжаем нормально
    next();
  }
});
export default router;

