<template>
  <div class="card">
    <div class="card-header">
      Вход
    </div>
    <div class="card-body">
      <form @submit.prevent="sendLogin">
        <label>Email:</label>
        <input type="email" v-model="login" required><br>
        <label>Пароль:</label>
        <input type="password" v-model="password" required><br>
        <button type="submit">Войти</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      login: '',
      password: '',
      hostApi: process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : 'http://localhost:5001', 
    };
  },
  methods: {
    async sendLogin() {
      console.log(this.login, this.hostApi);
      try {
        const response = await axios.post(this.hostApi+'/api/v1/login', {
          login: this.login,
          password: this.password
        });
        if (response.data.token){
          let token = response.data.token;
          localStorage.setItem('token', token);
          localStorage.setItem('clientId', response.data.clientId);
          //set delay 1000
          // Получение токена из localStorage
          
          // Добавление токена в заголовок Authorization для всех запросов
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          setTimeout(() => {

            this.$router.push('/');
          }, 2000);

          //this.$router.push('/');
        } else {
          alert(response.data.message);
          console.error(response.data);
        }
      } catch (error) {
        alert(error.response.data.message);
        console.error(error.response.data);
      }
    },
  }
};
</script>