
<template>
  <div id="app">
      <div class="container-fluid">
        <div class="navbar-nav">
          <nav class="navbar navbar-light bg-light">

          
            <!--h1>Home App</h1-->
            <router-link class="nav-link" to="/stream">Перейти к стриму</router-link>
            <router-link class="nav-link" to="/groupUtm">Группы УТМ</router-link>
            <router-link class="nav-link" to="/blockStat">Перейти к статистике</router-link>
            <router-link class="nav-link" to="/BlockStatXlsx">XLSX</router-link>
            <router-link class="nav-link" to="/cabinet">ЛК</router-link>
          </nav>
        </div>
      
        <router-view></router-view>
      </div>
  </div>
</template>
<script>


</script>
<script>
export default {
};
</script>