<template>
  <div class="card">
    <div class="card-header">
      Регистрация
    </div>
    <div class="card-body">
      <form @submit.prevent="register">
        <label>Email:</label>
        <input type="email" v-model="loginReg" required><br>
        <label>Пароль:</label>
        <input type="password" v-model="passwordReg" required><br>
        <button type="submit">Зарегистрироваться</button>
      </form>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  data() {
    return {
      loginReg: '',
      passwordReg: '',
      hostApi: process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : 'http://localhost:5001', 
    };
  },
  methods: {
    async register() {
      try {
        const response = await axios.post(this.hostApi+'/api/v1/register', {
          login: this.loginReg,
          password: this.passwordReg
        });
        this.$router.push('/login');
      } catch (error) {
        console.error(error.response.data);
      }
    },
  }
};
</script>